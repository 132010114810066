import React, { Component } from 'react';

class GameTile extends Component {
    // hide constructor if no extra logic, else React gives warning
    // constructor() {
    //     super();
    // }

    // properties: photoType = Cat or Person
    //   imgSrc = URL to the photo
    //   isReal = true (actual photo) / false (generated by AI GAN)
    //   buttonText = string
    //   showControls = bool
    //   handleVote = passed-in function

    handleClick = (event) => {
      event.preventDefault();
      this.setState({showControls: false});
      this.props.handleVote(event);
    }
    render() {
        return (
            <li>
                <h3>This is a {this.props.photoType}</h3>
                <img src={this.props.imgSrc} alt={"a nice "+this.props.photoType}/>
                {this.props.showControls && <>
                  <p>Cast your vote!</p>
                  <button value={this.props.isReal} onClick={this.props.handleVote}>{this.props.buttonText}</button>
                </>}
            </li>
        )
    }
}

export default GameTile;
